import { ReactElement, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AUTH_PATHS, BASE_PATHS } from "src/constant/path";

import useLocalStorage from "src/hooks/useLocalstorage";

type Props = {
  children: ReactElement;
};

const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();
  const [storedAccess, _] = useLocalStorage("clu", {});
  const [isLoad, setIsLoad] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (Object.entries(storedAccess).length === 0) {
      const path = `${process.env.REACT_APP_MAIN_URL}${BASE_PATHS.AUTH}/${AUTH_PATHS.SIGNIN}`;
      window.location.replace(`${path}`);
      setIsLoad(false);
    } else {
      setIsLoggedIn(true);
      setIsLoad(false);
    }
  }, [storedAccess]);

  if (isLoad) {
    return <p>Loading...</p>;
  }
  if (!isLoggedIn) {
    return (
      <Navigate to={`/auth/signin?te=1`} state={{ from: location }} replace />
    );
  } else {
    return children;
  }
};

export default PrivateRoute;
