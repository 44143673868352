import { useQuery } from "@tanstack/react-query";
import { createContext, FunctionComponent, useMemo } from "react";
import Api from "src/config";

export const UserProfileContext = createContext(null);

type Props = {
  children: any;
};

const UserProfileProvider: FunctionComponent<Props> = ({ children }) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["user_profile"],
    staleTime: 30000,
    queryFn: async () => {
      const { data } = await Api.get(`account`);

      return data;
    },
  });

  const contextValue = useMemo(
    () => ({
      data,
      isLoading,
      isFetching,
    }),
    [data, isLoading, isFetching]
  );

  return (
    <UserProfileContext.Provider value={contextValue}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
