import { Box, Text } from "@chakra-ui/react";
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AUTH_PATHS } from "src/constant/path";
import ErrorBoundary from "src/errorboundary";

// Auth pages
const SigninPage = lazy(() => import("src/modules/Auth/pages/Signin"));
const SignupPage = lazy(() => import("src/modules/Auth/pages/Signup"));
const VerifyPage = lazy(() => import("src/modules/Auth/pages/VerifyEmail"));
const CreatePasswordPage = lazy(
  () => import("src/modules/Auth/pages/CreatePassword")
);

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Navigate to={AUTH_PATHS.SIGNIN} replace />} />
        <Route
          path={AUTH_PATHS.SIGNIN}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <SigninPage />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={AUTH_PATHS.SIGNUP}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <SignupPage />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={AUTH_PATHS.VERIFY_EMAIL}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <VerifyPage />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={AUTH_PATHS.CREATE_PASSWORD}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <CreatePasswordPage />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Box sx={{ width: "50%", margin: "5em auto", textAlign: "center" }}>
              <Text variant="h1">Oopppss, you seem to be lost</Text>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
};

export default AuthRouter;
