import { Heading, Stack, Text } from "@chakra-ui/react";

import { NavLink } from "react-router-dom";

import { SIDENAV_MENU } from "src/constant/sidenav";

import { ReactComponent as LogoutIcon } from "src/assets/images/logout.svg";

const DesktopNavigation = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <Heading
        as={"h2"}
        color={"#0B0A0A"}
        fontSize={"24px"}
        fontWeight={600}
        mb={10}
      >
        IdEnroll
      </Heading>
      {SIDENAV_MENU.map((sidenav: any) => (
        <NavLink
          to={sidenav.path}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <Stack
            direction={"row"}
            cursor={"pointer"}
            alignItems={"center"}
            mb={10}
          >
            <sidenav.Icon />
            <Text color={"#A3A3A3"} fontWeight={500} fontSize={"14px"}>
              {sidenav.menu}
            </Text>
          </Stack>
        </NavLink>
      ))}

      <Stack direction={"row"} cursor={"pointer"} alignItems={"center"} mb={10}>
        <LogoutIcon />
        <Text
          color={"#A3A3A3"}
          fontWeight={500}
          fontSize={"14px"}
          onClick={handleLogout}
        >
          Logout
        </Text>
      </Stack>
    </>
  );
};

export default DesktopNavigation;
