// setup the config to preference

import axios, { InternalAxiosRequestConfig } from "axios";
import { AUTH_PATHS, BASE_PATHS } from "src/constant/path";
import { isAuthenticated } from "src/helpers/auth";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

Api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (isAuthenticated()) {
      const { cta, } = JSON.parse(localStorage.getItem("clu"));
      config.headers["Authorization"] = `Bearer ${cta}`;

    }
    return config;
  },
  (error: any) => {
    if (error.response && error.response.status > 500) {
      // Show a global alert or trigger a custom event
      console.log("Server error occured");
    }
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error?.code === "ERR_NETWORK") {
      throw error;
    } else if (error && error?.response?.status === 401) {
      localStorage.removeItem("clu");
      // localStorage.removeItem("cld");
      window.location.href = `${process.env.REACT_APP_MAIN_URL}${BASE_PATHS.AUTH}/${AUTH_PATHS.SIGNIN}`;
    } else if (error && error?.response?.status === 500) {
      throw error;
    } else {
      throw error;
    }
  },
);

export default Api;
