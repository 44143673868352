import { ReactComponent as ApplicationIcon } from "src/assets/images/application_icon.svg";
import { ReactComponent as DocumentIcon } from "src/assets/images/document_icon.svg";
import { ReactComponent as ProfileIcon } from "src/assets/images/profile_icon.svg";
// import { ReactComponent as NotificationIcon } from "src/assets/images/notification_icon.svg";
// import { ReactComponent as SettingIcon } from "src/assets/images/setting_icon.svg";
// import { ReactComponent as SupportIcon } from "src/assets/images/support_icon.svg";

export const SIDENAV_MENU = [
  {
    menu: "Applications",
    path: "/app/applications",
    Icon: ApplicationIcon,
    isComingSoon: false,
  },
  {
    menu: "Document Vault",
    path: "/app/document-vault",
    Icon: DocumentIcon,
    isComingSoon: false,
  },
  {
    menu: "Profile",
    path: "/app/profile",
    Icon: ProfileIcon,
    isComingSoon: true,
  },
  // {
  //   menu: "Notifiactions",
  //   path: "/app/notifications",
  //   Icon: NotificationIcon,
  //   isComingSoon: false,
  // },
  // {
  //   menu: "Settings",
  //   path: "/app/settings",
  //   Icon: SettingIcon,
  //   isComingSoon: false,
  // },
  // {
  //   menu: "Support",
  //   path: "/app/support",
  //   Icon: SupportIcon,
  //   isComingSoon: false,
  // },
];
