// setup typography to preference

export const typography = {
  h1: {
    // fontWeight: "900 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  h2: {
    // fontWeight: "800 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  h3: {
    // fontWeight: "700 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  h4: {
    // fontWeight: "600 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  h5: {
    // fontWeight: "500 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  h6: {
    // fontWeight: "400 !important",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
  p: {
    // fontWeight: "400",
    fontStyle: "normal !important",
    fontFamily: '"General Sans", sans-serif !important',
  },
};
