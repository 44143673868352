type BASE_PATHS_PROPS = {
  APP: string;
  AUTH: string;
};

type APP_PATHS_PROPS = {
  APPLICATIONS: string
  DOCUMENT_VAULT: string
  PROFILE: string
  NOTIFICATIONS: string
};

type LANDING_PATHS_PROPS = {
  HOME: string,
  ABOUT: string,
  CREATE_ACCOUNT: string
  CONTACT: string
  PRIVACY_POLICY: string
};

type AUTH_PATHS_PROPS = {
  SIGNIN: string;
  SIGNUP: string;
  FORGOT_PASSWORD: string;
  RESET_PASSWORD: string;
  VERIFY_EMAIL: string;
  CREATE_PASSWORD: string;
};

export const BASE_PATHS: BASE_PATHS_PROPS = {
  APP: "app",
  AUTH: 'auth',
};

export const AUTH_PATHS: AUTH_PATHS_PROPS = {
  SIGNIN: "signin",
  SIGNUP: 'signup',
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  VERIFY_EMAIL: "verify-email",
  CREATE_PASSWORD: "create-password",
};


export const LANDING_PATHS: LANDING_PATHS_PROPS = {
  HOME: '/',
  ABOUT: 'about',
  CREATE_ACCOUNT: 'create-account',
  CONTACT: 'contact',
  PRIVACY_POLICY: 'privacy-policy'
}


export const APP_PATHS: APP_PATHS_PROPS = {
  APPLICATIONS: 'applications',
  DOCUMENT_VAULT: 'document-vault',
  PROFILE: 'profile',
  NOTIFICATIONS: 'notifications'
};
