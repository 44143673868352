import { Box, Text } from "@chakra-ui/react";
import { FunctionComponent, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { APP_PATHS } from "src/constant/path";
import FormStepsProvider from "src/context/FormSteps";
import ErrorBoundary from "src/errorboundary";
// import AppLayout from "src/layout/appLayout";

// App pages
const Applications = lazy(
  () => import("src/modules/MainApp/pages/Applications")
);
const DocumentVault = lazy(
  () => import("src/modules/MainApp/pages/DocumentVault")
);
const Profile = lazy(() => import("src/modules/MainApp/pages/Profile"));
const Notifications = lazy(
  () => import("src/modules/MainApp/pages/Notifications")
);

const AppRouter: FunctionComponent<Record<string, never>> = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Navigate to="applications" replace />} />
        <Route
          path={APP_PATHS.APPLICATIONS}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <FormStepsProvider>
                  <Applications />
                </FormStepsProvider>
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.DOCUMENT_VAULT}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <DocumentVault />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.PROFILE}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <Profile />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.NOTIFICATIONS}
          element={
            <Suspense fallback={<>Please wait</>}>
              <ErrorBoundary>
                <Notifications />
              </ErrorBoundary>
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Box sx={{ width: "50%", margin: "5em auto", textAlign: "center" }}>
              <Text variant="h1">Oopppss, you seem to be lost</Text>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
