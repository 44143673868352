import LandingRoute from 'src/modules/Landing/routes'
import AuthRoute from 'src/modules/Auth/routes'
import AppRoute from 'src/modules/MainApp/routes'

import AppLayout from 'src/layout/appLayout'

import { BASE_PATHS } from './path';



type Props = {
  path: string;
  Component: React.FunctionComponent<Record<string, never>>;
  Layout?: React.FunctionComponent<any>;
  useAuth: boolean;
  sidenav?: React.FunctionComponent<any>
};


export const BASE_ROUTES: Props[] = [
  {
    path: `/*`,
    Component: LandingRoute,
    useAuth: false,
    Layout: null
  },
  {
    path: `${BASE_PATHS.AUTH}/*`,
    Component: AuthRoute,
    useAuth: false,
    Layout: null
  },
  {
    path: `${BASE_PATHS.APP}/*`,
    Component: AppRoute,
    useAuth: true,
    Layout: AppLayout
  },
];