import { Box, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import { UserProfileContext } from "src/context/UserProfile";

const Greeting = () => {
  const { data, isLoading } = useContext(UserProfileContext);
  return (
    <Box>
      <Heading
        color={"#0B0A0A"}
        fontSize={{ base: "18px", md: "24px" }}
        textTransform={"capitalize"}
        fontWeight={600}
      >
        Welcome,{" "}
        {!isLoading && `${data?.data?.first_name} ${data?.data?.last_name}`}
      </Heading>
    </Box>
  );
};

export default Greeting;
