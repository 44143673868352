import { Box, Flex, Stack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import Greeting from "src/components/Greeting";

import DesktopNav from "src/layout/appLayout/DesktopNav";
import MobileNav from "src/layout/appLayout/MobileNav";

type Props = {
  children: any;
};

const AppLayout: FunctionComponent<Props> = ({ children }) => {
  return (
    <Box
      // bg={"#00000080"}
      height={"100vh"}
    >
      <Box display={{ base: "block", md: "none" }}>
        <MobileNav />
      </Box>
      <Flex gap={5} p={{ base: 2, md: 5 }} bg={""}>
        <Box
          width={{ base: "unset", md: "18%" }}
          border={"1px solid #E6E6E6"}
          borderRadius={"16px"}
          height={"95vh"}
          p={5}
          position={{ base: "unset", md: "fixed" }}
          bg={"white"}
          display={{ base: "none", md: "block" }}
        >
          <DesktopNav />
        </Box>
        <Box
          width={{ base: "100%", md: "80%" }}
          px={{ base: 0, md: 5 }}
          position={{ base: "unset", md: "absolute" }}
          ml={{ base: "unset", md: "18%" }}
          pb={20}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={{ base: 10, md: 0 }}
            // bg={"#00000000"}
            // opacity={0.2}
          >
            <Greeting />
            {/* <Box width={"25%"}>
              <Input placeholder="Search for document" value="" type="text" />
            </Box> */}
          </Stack>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default AppLayout;
