import { useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { MdMenu, MdOutlineCancel } from "react-icons/md";
import { NavLink } from "react-router-dom";

import { SIDENAV_MENU } from "src/constant/sidenav";

import { ReactComponent as LogoutIcon } from "src/assets/images/logout.svg";

const MobileNavigation = () => {
  const [isToggledMenu, setIsToggledMenu] = useState(false);

  const handleToggleMenu = () => {
    setIsToggledMenu(!isToggledMenu);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <Box as="nav" py={4} px={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text fontWeight={600} fontSize={"20px"}>
            <a href="/">IdEnroll</a>
          </Text>
          <MdMenu size={"30px"} cursor={"pointer"} onClick={handleToggleMenu} />
        </Stack>
      </Box>
      {isToggledMenu && (
        <Box
          width={"100%"}
          px={4}
          py={4}
          height={"100vh"}
          position={"fixed"}
          top={0}
          right={0}
          left={0}
          background={"white"}
          zIndex={4}
        >
          <Box
            as="ul"
            listStyleType={"none"}
            gap={3}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={2}
              mb={6}
            >
              <Text fontWeight={600} fontSize={"20px"}>
                IdEnroll
              </Text>
              <MdOutlineCancel size={25} onClick={handleToggleMenu} />
            </Box>
            {SIDENAV_MENU.map((sidenav: any) => (
              <NavLink
                to={sidenav.path}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Stack
                  direction={"row"}
                  cursor={"pointer"}
                  alignItems={"center"}
                  mb={5}
                >
                  <sidenav.Icon />
                  <Text color={"#A3A3A3"} fontWeight={500} fontSize={"14px"}>
                    {sidenav.menu}
                  </Text>
                </Stack>
              </NavLink>
            ))}
            <Stack
              direction={"row"}
              cursor={"pointer"}
              alignItems={"center"}
              mb={10}
            >
              <LogoutIcon />
              <Text
                color={"#A3A3A3"}
                fontWeight={500}
                fontSize={"14px"}
                onClick={handleLogout}
              >
                Logout
              </Text>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileNavigation;
